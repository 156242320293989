.sponsors-bar {
  height: 180px;
  overflow: hidden;
  background: transparent;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 150px; /* Add margin to position below header */
  z-index: 2; /* Ensure it's below the header which typically has z-index: 2 */
}

.scrolling-track {
  display: flex;
  gap: 2rem;
  animation: scroll-left 30s linear infinite;
  width: max-content;
  align-items: center;
}

.scrolling-track a {
  text-decoration: none;
  display: inline-block;
}

.sponsor-logo {
  height: 100px;
  object-fit: contain;
  flex-shrink: 0;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.05); // very subtle background
  border-radius: 8px;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.6));
}


@keyframes scroll-left {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
