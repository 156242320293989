/* MainPage.scss */

.main-content {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.background-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7); // 70% overlay = 30% image visibility
  z-index: 1;
  width: 100%;
  height: 100%;
}

.main-content > *:not(.background-overlay) {
  position: relative;
  z-index: 2;
}

.content-wrapper {
  text-align: center;
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  .tagline {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }

  .cta-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .primary-button,
    .secondary-button {
      padding: 0.75rem 1.5rem;
      border: none;
      border-radius: 6px;
      font-size: 1rem;
      font-weight: bold;
      cursor: pointer;
      text-decoration: none;
      display: inline-block;
    }

    .primary-button {
      background-color: #ffcc00;
      color: #000;
    }

    .secondary-button {
      background-color: transparent;
      border: 2px solid #ffcc00;
      color: #fff;
    }
  }
}

.main-page {
  padding: 2rem;

  .sponsors {
    text-align: center;

    .sponsor-logos {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1.5rem;

      img {
        max-width: 100px;
        height: auto;

        @media (max-width: 768px) {
          max-width: 100px;
        }
      }
    }
  }
}
