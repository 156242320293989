/* src/styles/gallery.scss */

.gallery-page {
    padding-top: 0px;
    background-color: #f9f9f9;
  }
  
  .gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  
    .gallery-item {
      cursor: pointer;
      overflow: hidden;
      border-radius: 12px;
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 12px;
      }
  
      &:hover {
        transform: scale(1.03);
      }
    }
  }
  